<template>
  <b-collapse id="filter-box" class="client-list-filter">
    <div class="card-header py-0 border-0">
      <div class="row">
        <div class="col-12 col-lg-6 mb-5">
          <v-text-field
            label="Search With Name or Email or Mobile No. or Squad Name"
            outlined
            dense
            hide-details
            v-model.trim="query.search"
          ></v-text-field>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="filteredIndustries"
            item-text="name"
            item-value="id"
            label="Industry"
            dense
            hide-details
            outlined
            v-model="query.industry"
          >
            <template v-slot:prepend-item>
              <div class="px-5 mb-4">
                <v-text-field
                  v-model.trim="industrySearch"
                  placeholder="Search"
                  hide-details
                  class="mt-0 pt-0 text-h6 w-100"
                ></v-text-field>
              </div>
            </template>
          </v-select>
        </div>

        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="serviceTypeOptions"
            item-text="text"
            item-value="value"
            label="Service Type"
            outlined
            dense
            hide-details
            v-model="query.serviceType"
            :menu-props="{ left: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="marketingCampaignList"
            item-text="name"
            item-value="id"
            label="Campaign"
            outlined
            dense
            hide-details
            v-model="query.marketingCampaign"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="utilizations"
            item-text="text"
            item-value="value"
            label="Utilization"
            outlined
            dense
            hide-details
            v-model="query.utilization"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="clientStatus"
            item-text="text"
            item-value="value"
            label="Client Status"
            outlined
            dense
            hide-details
            v-model="query.clientStatus"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-6 mb-5">
          <v-select
            :items="dealOwners"
            item-text="name"
            item-value="id"
            label="Deal Owners"
            outlined
            dense
            hide-details
            v-model="query.dealOwner"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-6 mb-5">
          <v-select
            :items="multiSquadsList"
            item-text="text"
            item-value="value"
            label="Multi Squads"
            outlined
            dense
            hide-details
            v-model="query.multiSquads"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>

        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="signupSourceList"
            item-text="name"
            item-value="id"
            label="Registration Type"
            outlined
            multiple
            dense
            hide-details
            v-model="query.signupSource"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="signupCampaignList"
            label="Signup Campaign"
            outlined
            multiple
            dense
            hide-details
            v-model="query.signupCampaign"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="signupMediumList"
            label="Signup Medium"
            multiple
            outlined
            dense
            hide-details
            v-model="query.signupMedium"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-dialog
            ref="rangeMenuDialog"
            v-model="rangeMenu"
            :return-value.sync="query.range"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="query.range"
                icon="mdi-calendar"
                label="Range"
                readonly
                dense
                hide-details
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="query.range" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="rangeMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="dataRangeSorting"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="col-6 col-lg-6 mb-5">
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-5 col"
            @click="applyFilter(true)"
          >
            Apply
          </button>
        </div>
        <div class="col-6 col-lg-6 mb-5">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-5 col"
            @click="resetQuery"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const initQuery = {
  search: "",
  industry: null,
  serviceType: null,
  utilization: null,
  clientStatus: null,
  dealOwner: null,
  marketingCampaign: null,
  multiSquads: null,
  signupSource: null,
  registerType: null,
  range: [],
  signupMedium: null,
  signupCampaign: null,
};
export default {
  name: "ClientsListFilter",
  async created() {
    this.getMarketingCampaignList({ type: 2 });
    await this.getIndustries();
    await this.getDealOwners();
    this.getSignupSourceList({ type: 2 });
    this.getSignupCampaignList();
    this.getSignupMediumList();
  },
  data() {
    return {
      range: null,
      rangeMenu: false,
      multiSquadsList: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      registrationTypes: [
        {
          text: "Signed Up",
          value: 0,
        },
      ],
      serviceTypeOptions: [
        {
          text: "Team as a serivce",
          value: 1,
        },
        {
          text: "Creative Lab",
          value: 2,
        },
        {
          text: "CTO as a serivce",
          value: 3,
        },
        {
          text: "Payroll Management",
          value: 4,
        },
      ],
      utilizations: [
        {
          text: "Full-time",
          value: 1,
        },
        {
          text: "Part-time",
          value: 2,
        },
        {
          text: "Depends on the cost",
          value: 3,
        },
      ],
      clientStatus: [
        {
          text: "Uncompleted lead",
          value: 0,
        },
        {
          text: "Completed lead",
          value: 1,
        },
        {
          text: "Active  Client",
          value: 2,
        },
      ],
      query: { ...initQuery },
      isLoadingSkills: false,
      industrySearch: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      industries: "clients/getIndustries",
      dealOwners: "clients/getDealOwners",
      marketingCampaignList: "shared/getMarketingCampaignList",
      signupSourceList: "shared/getSignupSourceList",
      signupCampaignList: "shared/getSignupCampaignList",
      signupMediumList: "shared/getSignupMediumList",
    }),
    filteredIndustries() {
      return !this.industrySearch
        ? this.industries
        : this.industries.filter((skill) =>
            skill.name.toLowerCase().includes(this.industrySearch.toLowerCase())
          );
    },
  },
  methods: {
    ...mapActions({
      getIndustries: "clients/getIndustries",
      getDealOwners: "clients/getDealOwners",
      getMarketingCampaignList: "shared/getMarketingCampaignList",
      getSignupSourceList: "shared/getSignupSourceList",
      getSignupCampaignList: "shared/getSignupCampaignList",
      getSignupMediumList: "shared/getSignupMediumList",
    }),
    dataRangeSorting() {
      this.$refs.rangeMenuDialog.save(this.query.range);
      this.query.range = this.query.range.sort().join(" - ");
    },
    applyFilter(validateFirst) {
      let {
        search,
        industry,
        serviceType,
        utilization,
        clientStatus,
        dealOwner,
        marketingCampaign,
        range,
        signupSource,
        multiSquads,
        signupCampaign,
        signupMedium,
      } = this.query;
      if (
        validateFirst &&
        !search &&
        industry === null &&
        serviceType === null &&
        utilization === null &&
        clientStatus == null &&
        dealOwner == null &&
        marketingCampaign === null &&
        !range.length &&
        multiSquads === null &&
        signupSource === null &&
        signupCampaign === null &&
        signupMedium === null
      ) {
        this.$toasted.show("Please Add At Least One Filter", {
          type: "error",
          icon: "error",
        });
        return;
      }
      let data = {};
      if (search) data.search = search;
      if (industry !== null) data.industry = industry;
      if (serviceType !== null) data.serviceType = serviceType;
      if (utilization !== null) data.utilization = utilization;
      if (clientStatus !== null) data.clientStatus = clientStatus;
      if (dealOwner !== null) data.dealOwner = dealOwner;
      if (marketingCampaign !== null)
        data.marketingCampaign = marketingCampaign;
      if (multiSquads !== null) data.multiSquads = multiSquads;
      if (signupSource !== null) data.signupSource = signupSource;
      if (signupCampaign !== null) data.signupCampaign = signupCampaign;
      if (signupMedium !== null) data.signupMedium = signupMedium;
      if (range.length) data.registerDate = range;

      this.$emit("applyFilter", data);
    },
    resetQuery() {
      this.query = { ...initQuery };
      this.applyFilter();
    },
  },
};
</script>
